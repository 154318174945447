<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3>Agregar administrador</h3>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12" style="padding-bottom: 1px">
            <v-text-field
              v-model="busqueda"
              name="SG-busqueda"
              label="Nombre o código"
              type="text"
              :loading="loading"
              :disabled="loading"
              color="blue-grey"
              outlined
              dense
              @keyup.enter="buscarUsuario"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" style="padding-top: 1px">
            <v-card flat class="scroll">
              <v-list>
                <v-list-item
                  v-for="(usuario, index) in usuariosLocal"
                  :key="`encontrado-${index}`"
                >
                  <v-list-item-icon class="avatar-item">
                    <v-icon size="40">mdi-account-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ usuario.nombre }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      usuario.codigo
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="!usuario.admin">
                    <v-btn
                      icon
                      color="green darken-1"
                      :loading="loading"
                      @click="agregarAdministrador(usuario._id)"
                      ><v-icon>mdi-account-plus</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="$emit('cancelar')"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  buscarUsuariosService,
  agregarAdminSesionService,
} from "../administrar.service";

export default {
  name: "agregarAdministrador",
  props: ["mostrar", "idSesion", "administradores"],
  computed: {
    ...mapGetters(["userAdmin"]),
    usuariosLocal() {
      const usuarios = [];
      this.usuarios.forEach((usuario) => {
        const admin = this.administradores.find((x) => x.codigo == usuario.codigo);
        const usuarioLocal = {
          ...usuario,
          admin: admin ? true : false,
        };
        usuarios.push(usuarioLocal);
      });
      return usuarios;
    },
  },
  data() {
    return {
      loading: false,
      busqueda: "",
      usuarios: [],
    };
  },
  methods: {
    async buscarUsuario() {
      this.usuarios = [];
      if (this.busqueda.length > 2) {
        try {
          this.loading = true;
          const sendData = { busqueda: this.busqueda };
          const serverResponse = await buscarUsuariosService(sendData);
          this.loading = false;
          this.$validateResponse(serverResponse);
          if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
          else this.usuarios = serverResponse.usuarios;
        } catch (error) {
          this.usuarios = [];
          this.loading = false;
          this.$notificarError(error);
        }
      }
    },
    async agregarAdministrador(idUsuario) {
      try {
        this.loading = true;
        const sendData = { idSesion: this.idSesion, idUsuario };
        const serverResponse = await agregarAdminSesionService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          throw new Error(serverResponse.mensaje);
        } else {
          const emitData = { administrador: serverResponse.administrador };
          this.$emit("administradorAgregado", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>

<style>
.avatar-item {
  margin-right: 15px !important;
}
</style>