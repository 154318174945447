<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3 v-if="sesionAbierta">Cerrar sesión</h3>
        <h3 v-else>Abrir sesión</h3>
      </v-toolbar>
      <v-container>
        <v-card-title>
          ¿Está seguro en {{ sesionAbierta ? "cerrar" : "abrir" }} la sesión?
        </v-card-title>
        <v-card-subtitle v-if="!sesionAbierta">
          Esta acción dejará visible la sesión a los usuarios.
        </v-card-subtitle>
        <v-card-subtitle v-else>
          Esta acción ocultará la sesión a los usuarios.
        </v-card-subtitle>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          dark
          :loading="loading"
          @click="abrirCerrarAction()"
          >Aceptar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { abrirCerrarSesionService } from "./administrar.service";

export default {
  name: "abrirCerrarSesion",
  props: ["mostrar", "idSesion", "sesionAbierta"],
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async abrirCerrarAction() {
      try {
        this.loading = true;
        const sendData = {
          idSesion: this.idSesion,
        };
        const serverResponse = await abrirCerrarSesionService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        else
          this.$emit("sesionAbierta", {
            sesionAbierta: serverResponse.sesionAbierta,
          });
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>

<style>
.avatar-item {
  margin-right: 15px !important;
}
</style>