<template>
  <v-row style="padding: 0px 15px 0px 15px;">
    <v-col cols="6">
      <v-checkbox v-model="enviarCorreos" label="Enviar correos" @change="editarPermisos"></v-checkbox>
    </v-col>
    <v-col cols="6">
      <v-checkbox v-model="eliminarAsistentes" label="Eliminar asistentes" @change="editarPermisos"></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
import { modificarPermisosAdminSesionService } from "../administrar.service";

export default {
  name: "permisosAdministrador",
  props: ["idAdministrador", "permisoEnviarCorreos", "permisoEliminarAsistentes"],
  components: {},
  data() {
    return {
      enviarCorreos: false,
      eliminarAsistentes: false
    }
  },
  created() {
    this.enviarCorreos = this.permisoEnviarCorreos;
    this.eliminarAsistentes = this.permisoEliminarAsistentes;

  },
  methods: {
    async editarPermisos(){
      try {
        this.loading = true;
        const sendData = {
          idAdministrador: this.idAdministrador,
          permisoEnviarCorreos: this.enviarCorreos,
          permisoEliminarAsistentes: this.eliminarAsistentes
        };
        const serverResponse = await modificarPermisosAdminSesionService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        else {
          const emitData = {
            idAdministrador: serverResponse.administrador._id,
            permisoEnviarCorreos: serverResponse.administrador.permisoEnviarCorreos,
            permisoEliminarAsistentes: serverResponse.administrador.permisoEliminarAsistentes
          };
           this.$emit("permisosModificados", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
      
    },
  }
  
}
</script>
