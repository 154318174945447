<template>
  <v-container fluid>
    <v-card outlined>
      <v-toolbar dense flat>
         <asistentesMenuComponent 
          v-if="asistentes.length != 0"
          :userAdmin="userAdmin"
          @crearExcel="crearExcel"
          @reenviarCorreos="validarReenvioCorreos = true"
        />
        <div class="text-h6">Asistentes</div>
         <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :color="envioCorreos?'green darken-3':'gray'"
              icon
              class="ma-1"
              >
              <v-icon v-if="envioCorreos">mdi-email-check</v-icon>
              <v-icon v-else>mdi-email</v-icon>
              </v-btn>
          </template>
          <span v-if="envioCorreos">El envio de correos con enlaces de votación esta activo</span>
          <span v-else>El envio de correos esta inactivo</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-menu offset-x left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small color="primary" dark fab v-bind="attrs" v-on="on"
              ><v-icon>mdi-account-multiple-plus</v-icon></v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="agregarAsistente = true">
              <v-list-item-title>Manual</v-list-item-title>
              <v-list-item-icon
                ><v-icon>mdi-keyboard-outline</v-icon></v-list-item-icon
              >
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item @click="agregarAsistenteQR = true">
              <v-list-item-title>QR Scanner</v-list-item-title>
              <v-list-item-icon><v-icon>mdi-qrcode-scan</v-icon></v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-col v-if="this.loadingCorreos" cols="2">
        <v-progress-linear indeterminate color="yellow darken-2" rounded></v-progress-linear>
        <v-input disabled>Enviados: {{this.correosEnviados}} de {{this.asistentesLocales.length}}</v-input>
      </v-col>
        

      <v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="asistentesLocales"
        :search="search"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, -1],
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Elementos por página',
        }"
      >
        <!-- Correo -->
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, index) in items" :key="`item-${index}`">
              <td>{{ item.numero }}</td>
              <td>{{ item.codigo }}</td>
              <td>{{ item.funcionario }}</td>
              <td>{{ item.calidad }}</td>
              <td>{{ item.categoria }}</td>
              <td>{{ item.dependencia.nombre }}</td>
              <!-- <td>{{ item.voto }}</td> -->
              <td>{{ item.hora }}</td>
              <td>
                <botonesEnviarCorreoComponent
                  :admin="isAdminCorreo()"
                  :correoEnviado="item.correoEnviado"
                  :loadingCorreos="loadingCorreos"
                  :enlaceVotacion="item.enlaceVotacion"
                  :envioCorreos="envioCorreos"
                  @enviarCorreo="enviarCorreo(item)"
                />
              </td>
              <td>
                <v-btn
                  v-if="isAdminEliminar()"
                  icon
                  :disabled="loading || loadingCorreos"
                  @click="(eliminarAsistente = true), (asistente = item)"
                >
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <agregarAsistenteComponent
      v-if="agregarAsistente"
      :mostrar="agregarAsistente"
      :idSesion="idSesion"
      :consejeros="consejeros"
      :asistencias="asistencias"
      @cancelar="agregarAsistente = false"
      @asistenciaAgregada="asistenciaAgregada"
    />

    <agregarAsistenteQRComponent
      v-if="agregarAsistenteQR"
      :mostrar="agregarAsistenteQR"
      :idSesion="idSesion"
      :consejeros="consejeros"
      @cancelar="agregarAsistenteQR = false"
      @asistenciaAgregada="asistenciaAgregada"
    />

    <eliminarAsistenteComponent
      v-if="eliminarAsistente"
      :mostrar="eliminarAsistente"
      :idSesion="idSesion"
      :asistente="asistente"
      @cancelar="(eliminarAsistente = false), (asistente = null)"
      @asistenteEliminado="asistenteEliminado"
    />

    <enviarCorreoVotacionComponent
      v-if="enviarCorreoVotacion.mostrar"
      :mostrar="enviarCorreoVotacion.mostrar"
      :idSesion="idSesion"
      :asistente="enviarCorreoVotacion.asistente"
      @cancelar="cancelarEnviarCorreo"
      @asistenciaModificada="asistenciaModificada"
    />

    <v-dialog v-model="validarReenvioCorreos" persistent max-width="350" hide-overlay transition="scale-transition">
      <v-card>
        <v-toolbar dense color="primary" dark flat class="text-h6">Reenviar correos</v-toolbar>
        <v-container grid-list-md>
          <p><b>¿Está seguro que desea reenviar los correos a los asistentes registrados?</b></p><br>
          <i>Esta acción no podrá ser revertida</i>
        </v-container>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" 
              small 
              outlined 
              :disabled="loading" 
              @click="validarReenvioCorreos = false">Cancelar</v-btn>
              <v-btn color="primary" 
              small
              dark
              :loading="loading" 
              @click="reenviarCorreos()">Reenviar</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import agregarAsistenteComponent from "./agregarAsistente.vue";
import agregarAsistenteQRComponent from "./agregarAsistenteQR.vue";
import eliminarAsistenteComponent from "./eliminarAsistente.vue";
import enviarCorreoVotacionComponent from "./enviarCorreo.vue";
import botonesEnviarCorreoComponent from "./botonesEnviarCorreo.vue";
import asistentesMenuComponent from "./asistentesMenu.vue";

import { exportarExcel, enviarCorreoVotacion } from "../administrar.service";


export default {
  name: "asistentesSesion",
  components: {
    agregarAsistenteComponent,
    agregarAsistenteQRComponent,
    eliminarAsistenteComponent,
    enviarCorreoVotacionComponent,
    botonesEnviarCorreoComponent,
    asistentesMenuComponent,
  },
  props: ["idSesion", "asistentes", "consejeros", "asistencias", "permisoEnviarCorreos", "permisoEliminarAsistentes", "nombreSesion", 'envioCorreos'],
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  data() {
    return {
      loading: false,
      loadingCorreos: false,
      search: "",
      headers: [
        { text: "#", value: "numero" },
        { text: "Código", value: "codigo" },
        { text: "Funcionario", value: "funcionario" },
        { text: "Calidad", value: "calidad" },
        { text: "Categoría", value: "categoria" },
        { text: "Dependencia", value: "dependencia.nombre" },
        // { text: "Voto", value: "voto" },
        { text: "Hora", value: "hora" },
        { text: "Correo", value: "correoEnviado" },
        { text: "", value: "quitarAsistente", sortable: false },
      ],
      agregarAsistente: false,
      agregarAsistenteQR: false,
      eliminarAsistente: false,
      enviarCorreoVotacion: {
        mostrar: false,
        asistente: null,
      },
      asistente: null,
      asistentesLocales: [],
      correosEnviados: 0,
      validarReenvioCorreos: false,
    };
  },
  watch: {
    asistentes() {
      this.crearAsistentesLocales();
    },
  },
  mounted() {
    this.crearAsistentesLocales();
  },
  methods: {
    asistenciaAgregada(data) {
      this.$emit("asistenciaAgregada", data);
    },
    asistenteEliminado(data) {
      this.$emit("asistenteEliminado", data);
    },
    asistenciaModificada(data) {
      this.$emit("asistenciaModificada", data);
    },
    isAdminCorreo() {
      if (this.userAdmin || this.permisoEnviarCorreos) return true;
      return false;
    },
    isAdminEliminar() {
      if (this.userAdmin || this.permisoEliminarAsistentes) return true;
      return false;
    },
    async crearExcel() {
      try {
        this.loading = true;
        const sendData = { asistentes: this.asistentes };

        const serverResponse = await exportarExcel(this.idSesion, sendData);
        this.loading = false;
        if (!serverResponse)
          throw new Error("Ocurrio un error al exportar el archivo");
        else {
          if (serverResponse.type == 'application/octet-stream') {
            const url = window.URL.createObjectURL(new Blob([serverResponse]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Asistencia-${this.nombreSesion}.xlsx`);
            document.body.appendChild(link);
            link.click();
          } else {
            throw new Error("Ocurrio un error al exportar el archivo");
          }
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    async reenviarCorreos() {
      this.validarReenvioCorreos = false;
      for (const asistente of this.asistentesLocales) {
        this.loadingCorreos = true;
        await this.tiempoCorreo(asistente);
      }
      this.correosEnviados = 0;
    },
    crearAsistentesLocales() {
      this.asistentesLocales = this.asistentes.map((asistente) => {
        return { ...asistente, loading: false };
      });
    },
    enviarCorreo(item) {
      const indexAsistente = this.asistentesLocales.findIndex(
        (x) => x.numero === item.numero
      );
      this.enviarCorreoVotacion.mostrar = true;
      this.enviarCorreoVotacion.asistente = this.asistentesLocales[indexAsistente];
    },
    cancelarEnviarCorreo() {
      this.enviarCorreoVotacion.mostrar = false;
      this.enviarCorreoVotacion.asistente = null;
    },
    tiempoCorreo(asistente) { 
      return new Promise(resolve => {
        setTimeout(async () => {
          //console.log("Entre", new Date().getMilliseconds());
          await this.mandarCorreo(asistente);
          this.loadingCorreos = false;
          resolve('Terminado');
        }, 2000);
      });
    },
    async mandarCorreo(asistente){
      if(asistente.enlaceVotacion) {
         try {
          const sendData = {
            idSesion: this.idSesion,
            codigo: asistente.codigo,
            correo: asistente.correo,
            enlace: asistente.enlaceVotacion,
          };

          const serverResponse = await enviarCorreoVotacion(sendData);
          
          this.$validateResponse(serverResponse);
          if (!serverResponse.ok) {
            throw new Error(serverResponse.mensaje);
          } else {
            this.$emit("asistenciaModificada", serverResponse.asistencia);
            this.$notificarSuccess("Correo enviado correctamente");
          }
        } catch (error) {
          this.$notificarError(error);
        }
        this.correosEnviados++;
      } else {
        const error = { message: `El asistente ${asistente.funcionario} no cuenta con enlace de votación`};
        this.correosEnviados++;
        this.$notificarError(error);
      }
    }
  },
};
</script>

<style>
.consejero {
  background-color: #f3f3f3;
}
</style>