import { patchServerRequest, getServerRequest, postServerRequest } from '../../../helpers/serverRequest';
import { store } from '../../../store/store';
const httpURL = store.getters.httpURL;

//Guardar texto de la invitación
export async function guardarCorreoService(idSesion, data){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/${idSesion}/invitacion`;
    return await patchServerRequest(url, data, config);
}

//ver la invitacion
export async function getInvitacionService(idSesion){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/${idSesion}/invitacion`;
    return await getServerRequest(url, config);
}

export async function subirCSV(file, sesion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token, "Content-Type": "multipart/form-data" } };
    const url = `${httpURL}/sesion/${sesion}/enlacesDrive`;
    return await postServerRequest(url, file, config);
}

// export async function enviarCorreo(idSesion){
//     const token = store.getters.sessionToken;
//     const config = { headers: { token } };
//     const url = `${httpURL}/sesion/invitacionConsejo/${idSesion}`;
//     return await postServerRequest(url, {}, config);
// }

export async function enviarCorreoPrueba(idSesion,data){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/invitacion/prueba/${idSesion}`;
    return await postServerRequest(url, data, config);
}

export async function enviarInvitacion(data){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/invitacion/sesion`;
    return await postServerRequest(url, data, config);
}

export async function qrService(idSesion, respuesta){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/${idSesion}/qr`;
    return await patchServerRequest(url, {respuesta}, config);
}

export async function ligaService(idSesion, respuesta){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/${idSesion}/liga`;
    return await patchServerRequest(url, {respuesta}, config);
}

export async function descargarLigas(sesion) {
    const token = store.getters.sessionToken;
    const config = {
        headers: { token },
        responseType: 'blob',
    };
    const url = `${httpURL}/sesion/invitacion/drive/${sesion}`;
    return await getServerRequest(url, config);
}

export async function descargarFallidos(sesion) {
    const token = store.getters.sessionToken;
    const config = {
        headers: { token },
        responseType: 'blob',
    };
    const url = `${httpURL}/sesion/invitacion/fallidos/${sesion}`;
    return await getServerRequest(url, config);
}

export async function guardarCopiaService(idSesion, data){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/${idSesion}/invitacion/${data.no}/copia`;
    return await patchServerRequest(url, {copia:data.copia}, config);
}

export async function reenviarCorreo(idSesion){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/invitacionConsejo/reenvio/${idSesion}`;
    return await postServerRequest(url, {}, config);
}

export async function eliminarService(idSesion, cc){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/${idSesion}/invitacion/eliminar/${cc}`;
    return await patchServerRequest(url,{}, config);
}

export async function enviarCorreoService(idSesion,consejero){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/invitacionConsejoIndividual/${idSesion}`;
    return await postServerRequest(url,{consejero: consejero}, config);
}
export async function enviarCorreoFallidoService(idSesion,consejero){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/invitacionConsejoFallido/${idSesion}`;
    return await postServerRequest(url,{consejero: consejero}, config);
}
export async function eliminarFallidosService(idSesion){
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/${idSesion}/enlacesFallidos`;
    return await postServerRequest(url,{}, config);
}

