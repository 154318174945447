<template>
  <v-container fluid>
    <v-card outlined>
      <v-toolbar dense flat>
        <div class="text-h6">Administradores</div>
        <v-spacer></v-spacer>
        <v-btn small color="primary" dark @click="agregarAdmin = true"
          >Agregar administrador</v-btn
        >
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col
            v-for="usuario in usuariosLocal"
            :key="usuario._id"
            cols="12"
            md="4"
            sm="6"
          >
            <v-card outlined>
              <v-list-item>
                <v-list-item-icon class="avatar-item">
                  <v-icon size="40">mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ usuario.nombre }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    usuario.codigo
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <adminMenuComponent
                    :idAdministrador="usuario._id"
                    @asistenciasAdministrador="asistenciasAdministradorAction"
                    @eliminarAdministrador="eliminarAdministradorAction"
                  />
 
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
                <permisosAdministradorComponent
                  :idAdministrador="usuario._id"
                  :permisoEnviarCorreos="usuario.permisoEnviarCorreos" 
                  :permisoEliminarAsistentes="usuario.permisoEliminarAsistentes"
                  @permisosModificados="permisosModificados"
                />
              <v-divider v-if="usuario.dependencias.length > 0"></v-divider>
              <v-list-item dense v-for="dependencia in usuario.dependencias" :key="dependencia._id">
                <v-list-item-title>{{dependencia.nombre}}</v-list-item-title>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <agregarAdministradorComponent
      v-if="agregarAdmin"
      :mostrar="agregarAdmin"
      :idSesion="idSesion"
      :administradores="administradores"
      @administradorAgregado="administradorAgregado"
      @cancelar="agregarAdmin = false"
    />

    <asistenciaAdministradorComponent
      v-if="asignarAsistencia.mostrar"
      :mostrar="asignarAsistencia.mostrar"
      :idSesion="idSesion"
      :dependencias="dependencias"
      :administrador="asignarAsistencia.administrador"
      @dependenciaAsignada="dependenciaAsignada"
      @dependenciaQuitada="dependenciaQuitada"
      @cancelar="asignarAsistencia.mostrar = false"
    />

    <eliminarAdministradorComponent
      v-if="eliminarAdministrador.mostrar"
      :mostrar="eliminarAdministrador.mostrar"
      :idSesion="idSesion"
      :idAdministrador="eliminarAdministrador.idAdministrador"
      @cancelar="eliminarAdministrador.mostrar = false"
      @administradorEliminado="administradorEliminado"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import adminMenuComponent from "./adminMenu.vue";
import agregarAdministradorComponent from "./agregarAdministrador.vue";
import asistenciaAdministradorComponent from "./asistenciaAdministrador.vue";
import eliminarAdministradorComponent from "./eliminarAdministrador.vue";
import permisosAdministradorComponent from "./permisosAdministrador.vue";

export default {
  name: "administradoresComponent",
  props: ["idSesion", "administradores", "dependencias"],
  components: {
    adminMenuComponent,
    agregarAdministradorComponent,
    asistenciaAdministradorComponent,
    eliminarAdministradorComponent,
    permisosAdministradorComponent,
  },
  computed: {
    ...mapGetters(["userAdmin"]),
    usuariosLocal() {
      const usuarios = [];
      this.administradores.forEach((admin) => {
        const usuario = {
          _id: admin._id,
          nombre: admin.nombre,
          codigo: admin.codigo,
          permisoEnviarCorreos: admin.permisoEnviarCorreos,
          permisoEliminarAsistentes: admin.permisoEliminarAsistentes,
          dependencias: [],
        };
        admin.dependencias.forEach((idDependencia) => {
          const dependencia = this.dependencias.find(
            (x) => x._id == idDependencia
          );
          if (dependencia) usuario.dependencias.push(dependencia);
        });
        usuarios.push(usuario);
      });
      return usuarios;
    },
  },
  data() {
    return {
      loading: false,
      agregarAdmin: false,
      asignarAsistencia: {
        mostrar: false,
        administrador: null,
      },
      eliminarAdministrador: {
        mostrar: false,
        idAdministrador: null,
      },
      enviarCorreos: false,
      eliminarAsistentes: false
    };
  },
  methods: {
    administradorAgregado(data) {
      this.$emit("administradorAgregado", data);
    },
    dependenciaAsignada(data) {
      this.$emit("dependenciaAsignada", data);
    },
    dependenciaQuitada(data) {
      this.$emit("dependenciaQuitada", data);
    },
    permisosModificados(data){
      this.$emit("permisosModificados", data);
    },
    eliminarAdministradorAction(data) {
      this.eliminarAdministrador.idAdministrador = data.idAdministrador;
      this.eliminarAdministrador.mostrar = true;
    },
    administradorEliminado(data) {
      this.eliminarAdministrador.idAdministrador = null;
      this.eliminarAdministrador.mostrar = false;
      this.$emit("administradorEliminado", data);
    },
    asistenciasAdministradorAction(data) {
      const administrador = this.administradores.find(
        (x) => x._id == data.idAdministrador
      );
      if (administrador) {
        this.asignarAsistencia.administrador = administrador;
        this.asignarAsistencia.mostrar = true;
      }
    },
  },
};
</script>

<style>
.red-text {
  color: brown;
}
</style>