<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3>Asignar enlace</h3></v-toolbar
      >
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="enlaceLocal"
              name="DP-enlace"
              label="Enlace"
              type="text"
              :disabled="loading"
              color="blue-grey"
              outlined
              dense
              :error-messages="enlaceErrors"
              @input="validarEnlace"
              @blur="validarEnlace"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          :class="{ 'disable-events': blockAceptar }"
          @click="asignarEnlace()"
          >Agregar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { url } from "vuelidate/lib/validators";

import { asignarEnlaceVotacionService } from "../administrar.service";

export default {
  name: "asignarEnlaceVotacion",
  props: ["mostrar", "idSesion", "idConsejero", "enlace"],
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userAdmin"]),
    blockAceptar() {
      if (this.loading) return true;
      if (this.enlaceErrors.length > 0) return true;
      return false;
    },
  },
  validations: {
    enlaceLocal: { url },
  },
  data() {
    return {
      loading: false,
      enlaceLocal: "",
      enlaceErrors: [],
    };
  },
  mounted() {
    if (this.enlace) this.enlaceLocal = this.enlace;
  },
  methods: {
    validarEnlace() {
      this.$v.enlaceLocal.$touch();
      this.enlaceErrors = [];
      !this.$v.enlaceLocal.url && this.enlaceErrors.push("Enlace no válido.");
    },
    async asignarEnlace() {
      try {
        this.loading = true;
        const sendData = {
          idSesion: this.idSesion,
          idConsejero: this.idConsejero,
          enlace: this.enlaceLocal,
        };

        const serverResponse = await asignarEnlaceVotacionService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
       
        if (!serverResponse.ok) {
          throw new Error(serverResponse.mensaje);
        } else {
          const emitData = { enlaceVotacion: serverResponse.enlaceVotacion };
          this.$emit("votacionEnlaceAgregado", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>
