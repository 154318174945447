import {
    getServerRequest,
    // postServerRequest,
    putServerRequest,
    // patchServerRequest,
    // deleteServerRequest
} from '../../../helpers/serverRequest';
import { store } from '../../../store/store';

const httpURL = store.getters.httpURL;

export async function agregarSeccionService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/documentos/seccion`;
    return await putServerRequest(url, data, config);
}

export async function cargarSeccionesService(idSesion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/documentos/${idSesion}`;
    return await getServerRequest(url, config);
}

export async function subirDocumentoSesionService(data, formData, uploadHandler) {
    const token = store.getters.sessionToken;
    // const config = { headers: { token } };
    const url = `${httpURL}/sesiones/documentos/${data.idSesion}/${data.idSeccion}`;
    const config = {
        headers: {
            token,
            'Content-Type': 'multipart/form-data'
        },
        ...uploadHandler
    };
    return await putServerRequest(url, formData, config);
}

export async function descargarDocumentoService(idDocumento) {
    const token = store.getters.sessionToken;
    const config = {
        headers: { token },
        responseType: 'blob',
    };
    const url = `${httpURL}/sesiones/documentos/documento/${idDocumento}`;
    return await getServerRequest(url, config);
}