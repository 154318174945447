<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3>Agregar sección</h3></v-toolbar
      >
      <v-container>
        <v-row>
          <v-col cols="12" style="padding-bottom: 1px">
            <v-text-field
              v-model="nombre"
              name="SG-nombre"
              label="Nombre"
              type="text"
              :loading="loading"
              :disabled="loading"
              color="blue-grey"
              outlined
              dense
              :error-messages="nombreErrors"
              @input="validarNombre"
              @blur="validarNombre"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          :class="{ 'disable-events': blockAceptar }"
          @click="agregarSeccion()"
          >Agregar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import { agregarSeccionService } from "./documentos.service";

export default {
  name: "agregarSeccionDocumentos",
  props: {
    mostrar: Boolean,
    idSesion: String,
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userAdmin"]),
    blockAceptar() {
      if (this.loading) return true;
      if (this.nombreErrors.length > 0) return true;
      if (this.nombre.length == 0) return true;
      return false;
    },
  },
  validations: {
    nombre: { required, minLength: minLength(5) },
  },
  data() {
    return {
      loading: false,
      nombre: "",
      nombreErrors: [],
    };
  },
  methods: {
    validarNombre() {
      this.$v.nombre.$touch();
      this.nombreErrors = [];
      !this.$v.nombre.required && this.nombreErrors.push("Campo requerido.");
      !this.$v.nombre.minLength &&
        this.nombreErrors.push("Mínimo 5 caracteres.");
    },
    async agregarSeccion() {
      try {
        this.loading = true;
        const sendData = {
          idSesion: this.idSesion,
          nombre: this.nombre,
        };
        const serverResponse = await agregarSeccionService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$notificarError(serverResponse.mensaje);
        } else {
          this.$emit("seccionAgregada", serverResponse.nuevaSeccion);
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>
