<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on"
        ><v-icon>mdi-dots-vertical</v-icon></v-btn
      >
    </template>
    <v-list dense>
      <v-list-item @click="$emit('asistenciasAdministrador', { idAdministrador })">
        <v-list-item-title>Dependencias</v-list-item-title>
      </v-list-item>
      <v-divider class="mx-2"></v-divider>
      <v-list-item @click="$emit('eliminarAdministrador', { idAdministrador })">
        <v-list-item-title class="red-text"
          >Eliminar administrador</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "adminMenu",
  props: ["idAdministrador"],
  data() {
    return {};
  },
  methods: {},
};
</script>