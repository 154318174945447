<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3>Eliminar administrador</h3>
      </v-toolbar>
      <v-container>
        <v-card-subtitle>
          ¿Está seguro en eliminar el administrador?
        </v-card-subtitle>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="red darken-1"
          small
          dark
          :loading="loading"
          @click="eliminarAdministrador"
          >Eliminar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { eliminarAdminSesionService } from "../administrar.service";

export default {
  name: "eliminarAdministrador",
  props: ["mostrar", "idSesion", "idAdministrador"],
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async eliminarAdministrador() {
      try {
        this.loading = true;
        const sendData = {
          idSesion: this.idSesion,
          idAdministrador: this.idAdministrador,
        };
        const serverResponse = await eliminarAdminSesionService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        else this.$emit("administradorEliminado", { idAdministrador: this.idAdministrador });
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>

<style>
.avatar-item {
  margin-right: 15px !important;
}
</style>