<template>
  <div>
    <v-dialog v-model="mostrar" max-width="800" persistent>
      <v-card :loading="false">
        <v-toolbar color="primary" dark dense flat>
          <h3>Favor de ingresar los correos para el aviso de la sesión</h3>
        </v-toolbar>

        <v-container>
          <v-row>
            <v-col cols="10">
              <v-text-field :disabled="loading" label="Correo" v-model="nuevoCorreo"></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-btn @click="agregaCorreo()">+</v-btn>
            </v-col>
            
            <v-col cols="12">
              <div class="correos-container">
                <v-col v-for="(correo,index) in correos" :key="index" cols="12">
                  {{ correo }}
                </v-col>
              </div>
            </v-col>
          </v-row>

            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn color="primary" small outlined @click="$emit('cancelar')" :loading="loading">Cancelar</v-btn>
              <v-btn color="primary" small @click="enviar()" :loading="loading" :disabled="correos.length == 0">Enviar correo</v-btn>
             </v-card-actions>

        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { enviarInvitacion } from './invitaciones.service';

export default {
  name: "correoCalendario",
  props: {
    mostrar: Boolean,
  },
  components: {},
  computed: {},
  data() {
    return {
      idSesion: this.$route.params.idSesion,
      nuevoCorreo: '',
      correos: [],
      loading: false,
    };
  },
  methods: {
    agregaCorreo() {
      let regex = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
      let valido = regex.test(this.nuevoCorreo);
      if (this.nuevoCorreo != '' && valido) {
        this.correos.push(this.nuevoCorreo);
        this.nuevoCorreo = '';
      }
    },
    async enviar() {
      if (this.correos.length == 0) {
        console.log("No hay correo");
        return;
      }
      this.loading = true;
      for (let i = 0; i < this.correos.length; i++) {
        const correo = this.correos[i];
        const snedData = {
          correo: correo,
          idSesion: this.idSesion,
        };
        const serverResponse = await enviarInvitacion(snedData);
       if(!serverResponse.ok) console.log(serverResponse)
        console.log(serverResponse.mensaje);
        this.$emit('cancelar')

      }
      this.loading = false;
    },
  },
}
</script>

<style>
.correos-container {
  max-height: 160px;
  overflow-y: auto;
  border: 1px solid #ccc;
}
</style>