<template>
  <v-dialog v-model="mostrar" persistent max-width="500">
    <v-card>
      <v-toolbar
        v-if="asistente.correoEnviado"
        dense
        color="primary"
        dark
        flat
        class="text-h6"
        >Reenviar correo votación</v-toolbar
      >
      <v-toolbar v-else dense color="primary" dark flat class="text-h6"
        >Enviar correo votación</v-toolbar
      >
      <v-container>
        <v-text-field
          v-model="correoLocal"
          label="Correo:"
          outlined
          dense
          :hint="inputHint()"
          :error-messages="errors.correo"
          @input="validarCorreo()"
          @blur="validarCorreo()"
        >
        </v-text-field>

        <v-checkbox
          v-model="editarEnlace"
          label="Editar enlace votación"
        ></v-checkbox>

        <v-textarea
          v-model="enlaceLocal"
          label="Enlace:"
          :disabled="!editarEnlace"
          outlined
          auto-grow
          dense
          :error-messages="errors.enlace"
          @input="validarEnlace()"
          @blur="validarEnlace()"
        >
        </v-textarea>
      </v-container>

      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          small
          dark
          color="primary"
          :loading="loading"
          :class="{
            'disable-events':
              errors.correo.length || errors.enlace.length > 0 || !enlaceLocal,
          }"
          @click="enviarCorreo()"
          >Enviar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, url } from "vuelidate/lib/validators";
import { enviarCorreoVotacion } from "../administrar.service";

export default {
  name: "enviarCorreoVotacion",
  props: {
    mostrar: Boolean,
    idSesion: String,
    asistente: Object,
  },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      editarEnlace: false,
      correoLocal: null,
      enlaceLocal: "",
      errors: {
        correo: [],
        enlace: [],
      },
    };
  },
  validations: {
    correoLocal: { required, email },
    enlaceLocal: { required, url },
  },
  created() {
    this.correoLocal = this.asistente.correo;
    this.enlaceLocal = this.asistente.enlaceVotacion;
  },
  methods: {
    validarCorreo() {
      this.$v.correoLocal.$touch();
      const errors = [];
      if (this.$v.correoLocal.$dirty) {
        !this.$v.correoLocal.required && errors.push("Campo requerido.");
        !this.$v.correoLocal.email && errors.push("Correo no válido.");
      }
      this.errors.correo = errors;
    },
    validarEnlace() {
      this.$v.enlaceLocal.$touch();
      const errors = [];
      if (this.$v.enlaceLocal.$dirty) {
        !this.$v.enlaceLocal.required && errors.push("Campo requerido.");
        !this.$v.enlaceLocal.url && errors.push("URL no válido.");
      }
      this.errors.enlace = errors;
    },
    async enviarCorreo() {
      try {
        this.loading = true;
        const sendData = {
          idSesion: this.idSesion,
          codigo: this.asistente.codigo,
          correo: this.correoLocal,
          enlace: this.enlaceLocal,
        };
        const serverResponse = await enviarCorreoVotacion(sendData);
        this.loading = false;
        if (!serverResponse.ok) {
          throw new Error(serverResponse.mensaje);
        } else {
          this.$emit("asistenciaModificada", serverResponse.asistencia);
          this.$notificarSuccess("Correo enviado correctamente");
        }
        this.$emit("cancelar");
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    inputHint() {
      if(this.asistente.correoEnviado) {
        return "El cambio de correo solo aplicará para este reenvío."
      } else {
        return "El cambio de correo solo aplicará para este envío."
      }
    }
  },
};
</script>
