export const ordenarObjetosAsc = function (arreglo, llave) {
    return arreglo.sort((a, b) => (a[llave] > b[llave]) ? 1 : ((b[llave] > a[llave]) ? -1 : 0));
};

export const ordenarObjetosDesc = function (arreglo, llave) {
    return arreglo.sort((a, b) => (b[llave] > a[llave]) ? 1 : ((a[llave] > b[llave]) ? -1 : 0));
};

export const ordenarObjetosAscDeep = function (arreglo, llave1, llave2) {
    return arreglo.sort((a, b) => (a[llave1][llave2] > b[llave1][llave2]) ? 1 : ((b[llave1][llave2] > a[llave1][llave2]) ? -1 : 0));
};
export const ordenarObjetosDescDeep = function (arreglo, llave1, llave2) {
    return arreglo.sort((a, b) => (b[llave1][llave2] > a[llave1][llave2]) ? 1 : ((a[llave1][llave2] > b[llave1][llave2]) ? -1 : 0));
};