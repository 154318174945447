<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on"
        ><v-icon>mdi-dots-vertical</v-icon></v-btn
      >
    </template>
    <v-list dense>
      <v-list-item v-if="!sesionAbierta" @click="$emit('abrirSesion')">
        <v-list-item-title>Abrir sesión</v-list-item-title>
      </v-list-item>
      <v-list-item v-else @click="$emit('cerrarSesion')">
        <v-list-item-title>Cerrar sesión</v-list-item-title>
      </v-list-item>
      <!-- <v-divider class="mx-2"></v-divider>
      <v-list-item @click="$emit('modificarSesion')">
        <v-list-item-title>Modificar sesión</v-list-item-title>
      </v-list-item>
      <v-divider class="mx-2"></v-divider> -->
      <!-- <v-list-item>
        <v-list-item-title class="red-text">Eliminar sesión</v-list-item-title>
      </v-list-item> -->
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "sesionMenu",
  props: ["sesionAbierta"],
  data() {
    return {};
  },
  methods:{

  }
};
</script>