<template>
  <v-list-item>
    <v-list-item-icon v-if="status == 'uploading'">
      <v-icon color="primary">mdi-progress-upload</v-icon>
    </v-list-item-icon>
    <v-list-item-action v-if="status == 'error'">
      <v-btn icon small @click="$emit('quitarDocumento')"
        ><v-icon color="red darken-1">mdi-close-circle</v-icon></v-btn
      >
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-subtitle>{{ documento.name }}</v-list-item-subtitle>
      <v-list-item-subtitle v-if="status == 'uploading'">
        <v-progress-linear :value="progreso"></v-progress-linear>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="status == 'error'">
        Se presentó un error al subir el documento
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { subirDocumentoSesionService } from "./documentos.service";

export default {
  name: "subirDocumento",
  props: {
    idSesion: String,
    idSeccion: String,
    numero: Number,
    documento: File,
  },
  data() {
    return {
      loading: false,
      progreso: 0,
      status: "uploading",
    };
  },
  mounted() {
    this.subirDocumento();
  },
  methods: {
    async subirDocumento() {
      try {
        const formData = new FormData();
        formData.append("archivo", this.documento);
        const uploadHandler = {
          onUploadProgress: (progressEvent) =>
            (this.progreso =
              (progressEvent.loaded * 100) / progressEvent.total),
        };
        const data = { idSesion: this.idSesion, idSeccion: this.idSeccion };
        const serverResponse = await subirDocumentoSesionService(
          data,
          formData,
          uploadHandler
        );
        if (!serverResponse.ok) throw new Error();
        else {
          const emitData = {
            numero: this.numero,
            nuevoDocumento: serverResponse.nuevoDocumento,
          };
          this.$emit("documentoAgregado", emitData);
        }
      } catch (error) {
        this.status = "error";
      }
    },
  },
};
</script>
