<template>
  <v-container fluid>
    <v-card outlined>
      <v-toolbar dense flat>
        <div class="text-h6">Enlaces para votación</div>
       <v-spacer></v-spacer>
        <v-btn small color="primary" @click="asignarEnlaceCSV = true"><v-icon>mdi-link</v-icon>Cargar enlaces para votación</v-btn>
      </v-toolbar>

      <v-container fluid>
        <v-row >
          <v-col>
            <v-switch
              v-model="sendCorreo"
              label='Enviar correo con enlace de votación'
              :color="sendCorreo ? 'green darken-3' : 'grey darken-1'"
              :loading="loadingCorreo"
              @change="enviarCorreo()"
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>

      <v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="consejerosLocal"
        :search="search"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, -1],
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Elementos por página',
        }"
      >
        <template v-slot:[`item.enlace`]="{ item }">
          {{ item.enlace }}
        </template>
        >
        <template v-slot:[`item.opciones`]="{ item }">
          <enlaceMenuComponent
            :idConsejero="item._id"
            :enlace="item.enlace"
            @asignarEnlace="asignarEnlaceAction"
            @eliminarEnlace="eliminarEnlaceAction"
          />
        </template>
      </v-data-table>
    </v-card>

    <asignarEnlaceComponent
      v-if="asignarEnlace.mostrar"
      :mostrar="asignarEnlace.mostrar"
      :idSesion="idSesion"
      :idConsejero="asignarEnlace.idConsejero"
      :enlace="asignarEnlace.enlace"
      @cancelar="cancelarEnlaceAction"
      @votacionEnlaceAgregado="votacionEnlaceAgregado"
    />

    <asignarEnlaceCSVComponent
      v-if="asignarEnlaceCSV"
      :mostrar="asignarEnlaceCSV"
      :consejeros="consejerosLocal"
      :idSesion="idSesion"
      @cancelar="asignarEnlaceCSV = false"
      @votacionEnlaceAgregado="votacionEnlaceAgregado"
    />

    <eliminarEnlaceComponent 
      v-if="eliminarEnlace.mostrar"
      :mostrar="eliminarEnlace.mostrar"
      :idSesion="idSesion"
      :idConsejero="eliminarEnlace.idConsejero"
      @cancelar="(eliminarEnlace.mostrar = false, eliminarEnlace.idConsejero = null)"
      @votacionEnlaceEliminado="votacionEnlaceEliminado"
    />
  </v-container>
</template>

<script>
import enlaceMenuComponent from "./enlaceMenu.vue";
import asignarEnlaceComponent from "./asignarEnlace.vue";
import eliminarEnlaceComponent from "./eliminarEnlace.vue";
import asignarEnlaceCSVComponent from "./asignarEnlaceCSV.vue";
import {
  activarDesactivarEnviarCorreoService,
  getEnviarCorreoService,
} from "../administrar.service";

export default {
  name: "enlacesVotacion",
  components: {
    enlaceMenuComponent,
    asignarEnlaceComponent,
    eliminarEnlaceComponent,
    asignarEnlaceCSVComponent,
  },
  props: ["idSesion", "consejeros", "votacionEnlaces"],
  computed: {
    consejerosLocal() {
      const consejeros = [];
      this.consejeros.forEach((consejero, index) => {
        let nuevoConsejero = {
          _id: consejero._id,
          numero: index + 1,
          categoria: consejero.categoria,
          dependencia: consejero.dependencia.nombre,
          voto: consejero.voto,
          funcionario: consejero.usuarioMaster.nombre,
          enlace: null,
          codigo:consejero.usuarioMaster?consejero.usuarioMaster.codigo:null,
        };
        const votacionEnlace = this.votacionEnlaces.find(
          (x) => x.consejero == consejero._id
        );
        if (votacionEnlace) {nuevoConsejero.enlace = votacionEnlace.enlace,nuevoConsejero.voto=votacionEnlace.voto}
        consejeros.push(nuevoConsejero);
      });

      return consejeros;
    },
  },
  data() {
    return {
      loadingCorreo: false,
      search: "",
      headers: [
        { text: "#", value: "numero" },
        { text: "Funcionario", value: "funcionario" },
        // { text: "Calidad", value: "calidad" },
        { text: "Categoría", value: "categoria" },
        { text: "Dependencia", value: "dependencia" },
        { text: "Voto", value: "voto" },
        { text: "Enlace", value: "enlace" },
        { text: "", value: "opciones" },
      ],
      asignarEnlace: {
        mostrar: false,
        idConsejero: null,
        enlace: null,
      },
      eliminarEnlace: {
        mostrar: false,
        idConsejero: null,
      },
      asignarEnlaceCSV: false,
      sendCorreo: false,
    };
  },
  mounted() {
    this.cargarEnviarCorreos();
  },
  methods: {
    asignarEnlaceAction(data) {
      this.asignarEnlace.idConsejero = data.idConsejero;
      this.asignarEnlace.enlace = data.enlace;
      this.asignarEnlace.mostrar = true;
    },
    cancelarEnlaceAction() {
      this.asignarEnlace.idConsejero = null;
      this.asignarEnlace.enlace = null;
      this.asignarEnlace.mostrar = false;
    },
    eliminarEnlaceAction(data) {
      this.eliminarEnlace.mostrar = true;
      this.eliminarEnlace.idConsejero = data.idConsejero;
    },
    votacionEnlaceAgregado(data) {
      this.asignarEnlace.mostrar = false;
      this.$emit("votacionEnlaceAgregado", data);
    },
    votacionEnlaceEliminado(data) {
      this.eliminarEnlace.mostrar = false;
      this.$emit("votacionEnlaceEliminado", data);
    },
    async cargarEnviarCorreos() {
      try {
        const serverResponse = await getEnviarCorreoService(this.idSesion);
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$notificarError(serverResponse.mensaje);
        } else {
          this.sendCorreo = serverResponse.enviarCorreo;
        }
      } catch (error) {
        this.$notificarError(error);
      }
    },
    async enviarCorreo() {
      try {
        const sendData = {
          idSesion: this.idSesion,
          enviar: this.sendCorreo,
        };
        this.loadingCorreo = true;
        const serverResponse = await activarDesactivarEnviarCorreoService(
          sendData
        );
        this.loadingCorreo = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.sendCorreo = !this.sendCorreo;
          throw new Error(serverResponse.mensaje);
        } else {
          this.$emit('envioCorreos',this.sendCorreo)
          this.sendCorreo = serverResponse.enviarCorreo;
        }
      } catch (error) {
        this.sendCorreo = !this.sendCorreo;
        this.$notificarError(error);
      }
    },
  },
};
</script>

<style>
.containSwitch {
  width: 230px;
  margin-left: 15px;
  align-items: center;
}
</style>