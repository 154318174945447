<template>
  <v-card outlined>
    <v-toolbar densde flat>
      {{ seccion.nombre }}
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$refs.inputFile.click()"
            ><v-icon>mdi-file-upload-outline</v-icon></v-btn
          >
        </template>
        <span>Subir archivos</span>
        <input
          type="file"
          ref="inputFile"
          style="display:none;"
          multiple
          @input="subirDocumentos"
        />
      </v-tooltip>
    </v-toolbar>
    <v-divider
      v-if="seccion.documentos.length > 0 || colaSubida.length > 0"
      class="mx-3"
    ></v-divider>

    <v-list dense>
      <v-list-item
        v-for="(documento, index) in seccion.documentos"
        :key="`documento-${index}`"
      >
        <v-list-item-content>
          <v-list-item-title>
            <a
              href="javascript:void(0)"
              style="text-decoration: none"
              @click="descargarDocumento(documento)"
              >{{ documento.nombre }}</a
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- SECCION PARA SUBIR DOCUMENTOS -->
    <v-list v-if="colaSubida.length > 0">
      <subirDocumentoComponent
        v-for="(documento, index) in colaSubida"
        :key="`subida-${documento.numero}`"
        :idSesion="idSesion"
        :idSeccion="seccion._id"
        :numero="documento.numero"
        :documento="documento.file"
        @documentoAgregado="documentoAgregado"
        @quitarDocumento="quitarDocumentoCola(index)"
      />
    </v-list>
  </v-card>
</template>

<script>
import subirDocumentoComponent from "./subirDocumento.vue";
import { descargarDocumentoService } from "./documentos.service";

export default {
  name: "seccionDocumentos",
  components: {
    subirDocumentoComponent,
  },
  props: {
    idSesion: String,
    seccion: Object,
  },
  data() {
    return {
      colaSubida: [],
    };
  },
  methods: {
    subirDocumentos(event) {
      let numero = this.colaSubida.length + 1;
      for (const file of event.target.files) {
        this.colaSubida.unshift({ numero: numero++, file });
      }
    },
    documentoAgregado(data) {
      const emitData = {
        idSeccion: this.seccion._id,
        documento: data.nuevoDocumento,
      };
      this.$emit("documentoAgregado", emitData);
      const index = this.colaSubida.findIndex((x) => x.numero == data.numero);
      if (index >= 0) this.colaSubida.splice(index, 1);
    },
    quitarDocumentoCola(index) {
      this.colaSubida.splice(index, 1);
    },
    async descargarDocumento(documento) {
      try {
        const blobFile = await descargarDocumentoService(documento._id);
        const tipoDocumento = documento.documento.type;
        const url = window.URL.createObjectURL(new Blob([blobFile], { type: tipoDocumento }));
        if (tipoDocumento == "application/pdf") window.open(url);
        else {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", documento.nombre);
          link.click();
        }
      } catch (error) {
        this.$notificarError(error);
      }
    },
  },
};
</script>
