<template>
<v-dialog v-model="mostrar" fullscreen persisten>
    <v-card :loading="loading">
        <v-toolbar color="primary" dark dense flat>
            <h2>Invitación</h2>
            <v-spacer></v-spacer>
            <v-btn outlined small @click="$emit('cancelar')">Regresar</v-btn>
        </v-toolbar>
        
        <v-container>
            <v-card>
                <v-toolbar dense flat> <h4 style="color:#202945"> Vista previa del correo </h4>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click="guardarCorreo()">Guardar</v-btn>
            </v-toolbar>
            <v-divider style="color:darkblue" class="mx-1" ></v-divider>
            <v-container>
            <p>
                De: <b>Secretaría General "atencion.secretaria@administrativos.udg.mx"</b>
            </p>
            <p>
                Fecha: <b> {{ setFecha() }}</b>

            </p>
            <p style="font-size: 20px !important;">
                Asunto: <u style="color: orange; font-weight: bolder;"> {{ asunto }} </u>
            </p>
            <p>
                Destinatarios: Consejeros de la sesión
            </p>
            <!-- <v-container style="background-color: lightblue;"> -->
            <v-container>
                <div v-html="texto"></div>
            </v-container>
            <v-img v-if="qr" width="30%" :src="`${httpURL}/sesion/invitacion/qr/${idSesion}/${usuarioMaster}`"></v-img>
            </v-container>
                  <v-container>
                <div v-html="despedida"></div>
            </v-container>
        </v-card>
        </v-container>
    </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import { guardarCorreoService } from './invitaciones.service'
export default({
    name:"vistaPrevia",
    props:{
        mostrar: Boolean,
        asunto: String,
        texto: String,
        usuarioMaster: String,
        qr:Boolean,
        despedida:String,
        liga:Boolean
    },
    computed: {
    ...mapGetters(["httpURL"])
    },

    data(){
        return{
            loading:false,
            revisores:[],
            fechaLocal:null
        }
    },
    created() {
     this.idSesion = this.$route.params.idSesion;
    },
    methods:{
        setFecha() {
            // Date: lun, 12 dic 2022 a las 21:21
            return new DateTime.now().toLocaleString(DateTime.DATETIME_MED) + " hrs."
        },

        async guardarCorreo(){
            try{
                this.loading = true;
                const data = { asunto:this.asunto, texto:this.texto, despedida:this.despedida, liga:this.liga }
                const serverResponse = await guardarCorreoService(this.idSesion, data);
                this.loading = false;
               
                this.$validateResponse(serverResponse);
                if(!serverResponse.ok) throw new Error(serverResponse.mensaje);
                else {
                    this.$emit('invitacionGuardada', serverResponse.sesion.invitacion)
                    this.$emit('cancelar')
                }
            }
            catch(error){
            this.loading = false;
            this.$notificarError(error);
            }    
        }
    }
})
</script>
