<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on"
        ><v-icon>mdi-dots-vertical</v-icon></v-btn
      >
    </template>
    <v-list dense>
      <v-list-item v-if="!enlace" @click="$emit('asignarEnlace',{idConsejero, enlace})">
        <v-list-item-title>Asignar enlace</v-list-item-title>
      </v-list-item>
      <template v-else>
        <v-list-item @click="$emit('asignarEnlace', {idConsejero, enlace})">
          <v-list-item-title>Modificar enlace</v-list-item-title>
        </v-list-item>
        <v-divider class="mx-2"></v-divider>
        <v-list-item @click="$emit('eliminarEnlace', {idConsejero})">
          <v-list-item-title class="red-text"
            >Eliminar enlace</v-list-item-title
          >
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "enlaceMenu",
  props: ["idConsejero", "enlace"],
  data() {
    return {};
  },
  methods: {},
};
</script>