<template>
  <v-dialog v-model="mostrar" max-width="800" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3>Asistencias</h3>
        <v-spacer></v-spacer>
        <v-btn small outlined  :disabled="loading" @click="$emit('cancelar')">Cerrar</v-btn
        >
      </v-toolbar>
      <v-container>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  <v-switch
                    v-model="todos"
                    inset
                    :loading="loading"
                    @change="asignarDependencias()"
                  ></v-switch>
                  </th>
                <th class="text-left">Dependencia</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in dependenciasAdmin" :key="item._id">
                <td>
                  <v-switch
                    v-model="item.asignada"
                    inset
                    :loading="loading"
                    @change="asignarDependencia(item._id, item.asignada)"
                  ></v-switch>
                </td>
                <td>{{ item.nombre }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { modificarDependenciaAdminSesionService } from "../administrar.service";

export default {
  name: "asistenciasAdministrador",
  props: ["mostrar", "idSesion", "dependencias", "administrador"],
  watch:{
    dependenciasAdmin:function(val){
      let find = val.find(x=>{return !x.asignada})
      if(find) this.todos=false
      else this.todos=true
    }
  },
  mounted(){
      let find = this.dependenciasAdmin.find(x=>{return !x.asignada})
      if(find) this.todos=false
      else this.todos=true
  },
  computed: {
    ...mapGetters(["userAdmin"]),
    dependenciasAdmin() {
      const dependencias = [];
      this.dependencias.forEach((dependencia) => {
        const encontrada = this.administrador.dependencias.findIndex(
          (x) => x == dependencia._id
        );
        const item = {
          ...dependencia,
          asignada: encontrada >= 0 ? true : false,
        };
        dependencias.push(item);
      });

      return dependencias;
    },
    
    
  },
  data() {
    return {
      loading: false,
      todos:false
    };
  },
  methods: {
    async asignarDependencia(idDependencia,asigment) {
      let encontrada = -1
      if(this.administrador.dependencias.length){
        encontrada = this.administrador.dependencias.findIndex(x=>x ==idDependencia)
      }
      if(encontrada==-1 || this.administrador.dependencias.length==0 || asigment==false){
      try {
        this.loading = true;

        const sendData = {
          idAdministrador: this.administrador._id,
          idDependencia,
          asignada:asigment
        };
        const serverResponse = await modificarDependenciaAdminSesionService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        else {
          const emitData = {
            idAdministrador: this.administrador._id,
            idDependencia,
          };
          if (serverResponse.dependenciaAsignada)
            this.$emit("dependenciaAsignada", emitData);
          else this.$emit("dependenciaQuitada", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
        }
    },
    
    asignarDependencias(){
      this.dependencias.forEach(dep=>{
        this.asignarDependencia(dep._id,this.todos)
      })
    }
  },
};
</script>