<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "loadingSeccionesDocumentos",
};
</script>
