<template>
    <v-dialog v-model="mostrar" persistent max-width="500">
        <v-card>
            <v-toolbar dense color="red darken-4" dark flat class="text-h6">Eliminar asistente</v-toolbar>
            <v-container grid-list-md>
                <p><b>¿Está seguro de eliminar al asistente?</b> <br>
                <i>Esta acción no podrá ser revertida.</i> </p>
                <ul><li>{{asistente.codigo}}</li></ul>
                <ul><li>{{asistente.calidad}}</li></ul>
                <ul><li>{{asistente.funcionario}}</li></ul>
                <ul><li>{{asistente.categoria}}</li></ul>
                <ul v-if="asistente.divisionDepto"><li>{{asistente.divisionDepto}}</li></ul>
            </v-container>
            <v-divider class="mx-3"></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" 
                        small 
                        outlined 
                        :disabled="loading" 
                        @click="$emit('cancelar')">Cancelar</v-btn>
                        <v-btn
                        small
                        dark
                        color="red darken-4"
                        :loading="loading" 
                        @click="eliminar()">Eliminar</v-btn>
                    </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { eliminarAsistenteSesion } from "../administrar.service";

export default {
  name: "eliminarAsistente",
  props: {
    mostrar: Boolean,
    asistente: Object,
    idSesion: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async eliminar() {
      try {
        this.loading = true;

        const sendData = {
          idSesion: this.idSesion,
          codigo: this.asistente.codigo,
        };
        const serverResponse = await eliminarAsistenteSesion(sendData); 
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          throw new Error(serverResponse.mensaje);
        } else {
          this.$notificarSuccess('Asistente eliminado correctamente')
          this.$emit("asistenteEliminado", {idAsistencia: serverResponse.asistenciaEliminada});
        }
        this.$emit("cancelar");
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>
