<template>
  <v-container fluid>
    <v-card outlined>
      <v-toolbar dense flat>
        <div class="text-h6">Documentos</div>
        <v-spacer></v-spacer>
        <v-btn small color="primary" dark @click="agregarSeccion = true"
          >Agregar sección</v-btn
        >
      </v-toolbar>
      <loadingSeccionesComponent v-if="loading" />
      <v-container v-else fluid>
        <v-row>
          <v-col
            cols="4"
            v-for="(seccion, index) in secciones"
            :key="`seccion-${index}`"
          >
            <seccionDocumentosComponent
              :idSesion="idSesion"
              :seccion="seccion"
              @documentoAgregado="documentoAgregado"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <agregarSeccionComponent
      v-if="agregarSeccion"
      :mostrar="agregarSeccion"
      :idSesion="idSesion"
      @cancelar="agregarSeccion = false"
      @seccionAgregada="seccionAgregada"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import loadingSeccionesComponent from "./loadingSecciones.vue";
import seccionDocumentosComponent from "./seccionDocumentos.vue";
import agregarSeccionComponent from "./agregarSeccion.vue";

import { cargarSeccionesService } from "./documentos.service";

export default {
  name: "documentosComponent",
  props: {
    idSesion: String,
  },
  components: {
    loadingSeccionesComponent,
    seccionDocumentosComponent,
    agregarSeccionComponent,
  },
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  data() {
    return {
      loading: false,
      secciones: [],
      agregarSeccion: false,
    };
  },
  mounted() {
    this.cargarSecciones();
  },
  methods: {
    async cargarSecciones() {
      try {
        this.loading = true;
        const serverResponse = await cargarSeccionesService(this.idSesion);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) this.$notificarError(serverResponse.mensaje);
        else this.secciones = serverResponse.secciones;
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    seccionAgregada(nuevaSeccion) {
      this.secciones.push(nuevaSeccion);
      this.agregarSeccion = false;
    },
    documentoAgregado(data) {
      const index = this.secciones.findIndex((x) => x._id == data.idSeccion);
      this.secciones[index].documentos.push(data.documento);
    },
  },
};
</script>

<style>
.red-text {
  color: brown;
}
</style>
