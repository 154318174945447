<template>
  <v-menu offset-x origin="center center" transition="slide-x-transition">
    <template v-slot:activator="{ on: menu, attrs }" >
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }"
            ><v-icon>mdi-dots-vertical</v-icon></v-btn>
        </template>
        <span>Configuración</span>
      </v-tooltip>
    </template>
    <v-list dense :height="userAdmin ? '100':'60'" tile>
      <v-divider class="mx-3"></v-divider>
      <v-list-item @click="$emit('crearExcel')">
        <v-list-item-icon class="list-icon">
            <v-icon class="icon-size">mdi-file-excel</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>Exportar asistencias</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mx-3"></v-divider>
      <v-list-item @click="$emit('reenviarCorreos')" v-if="userAdmin">
        <v-list-item-icon class="list-icon">
            <v-icon class="icon-size">mdi-mailbox-open-up-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>Reenviar correos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mx-3"></v-divider>
    </v-list>
   </v-menu>
</template>
  
<script>
export default {
  name: "asistentesMenu",
  props: {
    userAdmin: Boolean ,
  },
  data() {
    return {};
  },
  methods:{

  }
};
</script>