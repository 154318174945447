<template>
  <v-dialog v-model="mostrar" persistent max-width="500">
    <v-card>
      <v-toolbar dense color="red darken-4" dark flat class="text-h6">Eliminar enlace</v-toolbar>
      <v-container grid-list-md>
          <p><b>¿Está seguro de eliminar el enlace?</b> <br>
          <i>Esta acción no podrá ser revertida.</i> </p>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" 
        small 
        outlined 
        :disabled="loading" 
        @click="$emit('cancelar')">Cancelar</v-btn>
        <v-btn
        small
        dark
        color="red darken-4"
        :loading="loading" 
        @click="eliminar()">Eliminar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { eliminarEnlaceVotacionService } from "../administrar.service";

export default {
  name: "eliminarEnlace",
  props: {
    mostrar: Boolean,
    idConsejero: String,
    idSesion: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async eliminar() {
      try {
        this.loading = true;

        const sendData = {
          idSesion: this.idSesion,
          idConsejero: this.idConsejero,
        };
        const serverResponse = await eliminarEnlaceVotacionService(sendData); 
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          throw new Error(serverResponse.mensaje);
        } else {
          this.$notificarSuccess('Enlace eliminado correctamente');
          this.$emit("votacionEnlaceEliminado", {idVotacionEnlace: serverResponse.enlaceEliminado});
        }
        this.$emit("cancelar");
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>
